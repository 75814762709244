import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=0bc45456&scoped=true"
import script from "./MenuBar.vue?vue&type=script&lang=js"
export * from "./MenuBar.vue?vue&type=script&lang=js"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=0bc45456&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc45456",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/cart.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuBar.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@dundle/locale/data/account.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuBar.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports