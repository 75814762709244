<template>
    <UINavBar id="menu" role="banner">
        <UIGridContainer class="menu-container d-flex">
            <UINavIcon class="d-inline-block d-md-none" @click.native.prevent.stop="onClickMenuIcon"></UINavIcon>

            <UINavBrand
                id="logo"
                :to="$locale.path('index')"
                class="d-inline-block mr-auto logo-container-min-width order-0"
                @click.native="hideSubmenu()"
            >
                <ThemeLogo />
            </UINavBrand>

            <MenuSearchInput v-if="supportsSearching && showSearchIcon" ref="searchInput" />

            <UINavItem
                v-if="showSearchIcon"
                typename="search"
                class="d-block d-md-none order-1 order-md-1"
                @click.native.prevent.stop="onClickSearch"
            >
                <template slot="icon">
                    <div class="search-icon-wrapper">
                        <UIIcon :name="['fal', 'search']" />
                    </div>
                </template>
            </UINavItem>

            <UINavItem
                id="menu-currency"
                class="d-block order-2 order-md-2"
                typename="currency"
                role="button"
                @click.native="
                    hideSubmenu(), hidePopup(), $analytics?.event('view_locale_switcher'), showModal('settings')
                "
                @keydown.enter.native="
                    hideSubmenu(), hidePopup(), $analytics?.event('view_locale_switcher'), showModal('settings')
                "
            >
                <template slot="icon">
                    <UIFlag :code="$locale.regio" class="large"></UIFlag>
                </template>
                <template slot="title">{{ mapLanguage($locale.language) }}</template>
                <template slot="details">
                    <span class="small">{{ getSelectedCurrency }}</span>
                    <span v-if="getSelectedCurrencySymbol" class="small">({{ getSelectedCurrencySymbol }})</span>
                </template>
                <template slot="popup">
                    <SettingsRedirectionPopup></SettingsRedirectionPopup>
                </template>
            </UINavItem>

            <UINavItem2
                v-if="false"
                id="menu-account"
                class="d-none d-md-block order-3"
                typename="account"
                role="button"
                @click.native.prevent.stop="navigateToAccount"
                @keydown.enter="navigateToAccount"
            >
                <template slot="icon">
                    <UIIcon :name="['fal', 'user-circle']" />
                </template>
                <template slot="title">{{ accountTitle }}</template>
                <template slot="details">
                    <span class="small">{{ accountSubTitle }}</span>
                </template>
            </UINavItem2>

            <UINavItem
                typename="cart"
                aria-label="cart"
                class="order-4"
                role="button"
                @click="$router.navigate({ path: $locale.path('cart'), query: $route.query })"
                @keydown.enter="$router.navigate({ path: $locale.path('cart'), query: $route.query })"
            >
                <template slot="icon">
                    <div class="cart-icon-wrapper">
                        <div v-show="itemsInCart != 0" class="items-in-cart font-weight-bold">
                            {{ getCartSymbol }}
                        </div>
                        <UIIcon class="cart-open" :name="['fal', 'shopping-cart']" />
                    </div>
                </template>
                <template slot="title">{{ translate('cart.title') }}</template>
                <template slot="details">
                    <span class="small">{{ $formatCurrency($cart.subTotal) }}</span>
                </template>
                <template slot="dropdown">
                    <CartPopup></CartPopup>
                </template>
            </UINavItem>
        </UIGridContainer>

        <UIOverlay v-if="overlayIsActive && overlayMenuIsActive" id="overlay" class="menu-overlay"></UIOverlay>
    </UINavBar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import {
    UINavBar,
    UIFlag,
    UINavIcon,
    UINavItem,
    UINavItem2,
    UINavBrand,
    UIGridContainer,
    UIIcon,
    UIOverlay,
} from '@dundle/ui/components';
import MenuSearchInput from './MenuSearchInput';
import { ThemeLogo } from '~/components/theme';

import TranslationMixin from '~/mixins/TranslationMixin';
export default {
    components: {
        MenuSearchInput,
        UIGridContainer,
        UINavIcon,
        UINavItem,
        UINavItem2,
        UINavBar,
        CartPopup: () => import('~/components/cart/CartPopup'),
        SettingsRedirectionPopup: () => import('~/components/settings/SettingsRedirectionPopup'),
        UIOverlay,
        ThemeLogo,
        UIIcon,
        UIFlag,
        UINavBrand,
    },

    mixins: [TranslationMixin],

    data() {
        return {
            activeBrand: false,
            activeCart: false,
            activeCurrency: false,
            activeMobile: false,
            scrollY: 0,
        };
    },

    computed: {
        ...mapGetters({
            getSelectedCurrency: 'currency/getSelectedCurrency',
            getSelectedCurrencySymbol: 'currency/getSelectedCurrencySymbol',
            menuIsActive: 'ui/menuIsActive',
            overlayIsActive: 'ui/overlayIsActive',
            overlayMenuIsActive: 'ui/overlayMenuIsActive',
            scrollPaddingRight: 'ui/scrollPaddingRight',
            headerDetail: 'route/getHeader',
        }),

        showSearchIcon() {
            const routeName = this.$route.name?.split(';');

            return (
                this.supportsSearching &&
                (this.headerDetail === 'version-search' ||
                    (routeName?.[routeName.length - 1] === 'index' && this.scrollY > 100))
            );
        },

        getCartSymbol() {
            return this.itemsInCart < 10 ? this.itemsInCart : '+';
        },
        itemsInCart() {
            return this.$store.state.cart.items.map(item => item.quantity).reduce((a, b) => a + b, 0);
        },

        accountTitle() {
            return this.isAuthenticated ? this.translate('account.myAccount') : this.translate('account.iAmGuest');
        },

        accountSubTitle() {
            if (this.isAuthenticated) {
                const atSign = this.$auth.user.email.indexOf('@');
                return this.$auth.user.email.slice(0, atSign);
            } else {
                return this.translate('account.createAccount');
            }
        },

        isAuthenticated() {
            return this.$auth.strategy.name === 'account' && this.$auth.loggedIn && this.$session.isLoaded;
        },

        supportsSearching() {
            return this.$locale.isTarget() || this.$locale.language === 'en';
        },
    },

    mounted() {
        if (this.$nuxt.$device.isMobile()) {
            window.addEventListener('scroll', this.scrollListener);
            this.scrollListener();
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollListener);
    },

    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
            showSearch: 'ui/showSearch',
            hidePopup: 'ui/hidePopup',
            hideSubmenu: 'ui/hideSubmenu',
        }),

        navigateToAccount() {
            if (this.isAuthenticated) {
                this.$router.navigate(this.$locale.path('account'));
            } else {
                this.showModal('login');
            }
        },

        redirect() {
            this.$router.navigate(this.$locale.path('cart'));
        },

        onClickMenuIcon() {
            if (this.menuIsActive) {
                this.$emit('menu:close');
            } else {
                this.$emit('menu:open');
            }
        },

        onClickSearch() {
            this.$refs.searchInput.forceFocus();
        },

        scrollListener() {
            this.scrollY = window.scrollY;
        },
    },
};
</script>
<style lang="scss" scoped>
#menu {
    width: 100%;
    border-bottom: none !important;
    z-index: 2;
    background: white;

    &.active-overlay {
        background: #fff;
    }

    .logo-container-min-width {
        min-width: 25%;
    }

    .cart-icon-wrapper {
        position: relative;
        color: $color-header-text-bold;

        .items-in-cart {
            width: 1.13rem;
            height: 1.13rem;
            line-height: 1.15rem;
            right: -0.3rem;
            top: calc(50% - 1.3rem);
            padding-left: 0.3rem;
            background: $color-button-primary;
            border-radius: 100%;
            position: absolute;
            font-size: 0.85rem;
            color: $color-button-text;
        }

        .cart-open {
            font-size: 1.75rem;
            vertical-align: -0.3em;
        }
    }

    #menu-account {
        svg {
            font-size: 1.75rem;
            color: $color-header-text-bold;
        }

        .small {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
        }
    }

    .container {
        padding-right: 0;

        @media screen and (max-width: 767px) {
            padding-left: 0;
        }
    }

    #logo {
        img {
            vertical-align: middle;
        }
    }

    .navBar-brand {
        height: 100%;
        padding: 0rem;
        line-height: 3rem;
        border-radius: 3px;

        img {
            vertical-align: middle;
        }
    }
    .cart-closed {
        margin-bottom: 0.25rem;
    }

    .search-icon-wrapper {
        font-size: 1.75rem;
        color: $color-header-text-bold;
    }
}
</style>

<i18n src="@dundle/locale/data/cart.json"></i18n>
<i18n src="@dundle/locale/data/account.json"></i18n>
